export default [
	{
		title: 'Bienvenidos a Quality Results!',
		image: 'https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/bg_1.jpg?alt=media&token=c11ea7f9-21a6-41bb-bcdc-3156c89ddf7d',
	},
	{
		title: 'Encuentra toda la información necesaria sobre nosotros',
		image: 'https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/bg_2.jpg?alt=media&token=8bcb9c3a-ebf4-49f4-8108-b46c329e4cef',
	},
	{
		title: 'Y sobre lo que hacemos',
		image: 'https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/bg_3.jpg?alt=media&token=c54df803-89ea-42b1-88f4-cf4ee582487f',
	},
	{
		title: 'Bienvenidos a Quality Results!',
		image: 'https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/bg_1.jpg?alt=media&token=c11ea7f9-21a6-41bb-bcdc-3156c89ddf7d',
	},
	{
		title: 'Encuentra toda la información necesaria sobre nosotros',
		image: 'https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/bg_2.jpg?alt=media&token=8bcb9c3a-ebf4-49f4-8108-b46c329e4cef',
	},
	{
		title: 'Y sobre lo que hacemos',
		image: 'https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/bg_3.jpg?alt=media&token=c54df803-89ea-42b1-88f4-cf4ee582487f',
	},

];

export const navItem = [
	{ text: 'Inicio', url: '#intro' },
	{ text: 'Quienes Somos', url: '#quienessomos' },
	{ text: 'Servicios', url: '#about' },
	{ text: 'Contáctanos', url: '#contact' },
	{ text: 'Trabaja con nosotros', url: '#trabaja' },
	{ text: 'Empleados', url: 'empleadoSitioEnDesarrollo.html' }
	
]

export const text = {
    header_text : "Quality Results"
}
export const block_header_text = {
	text : "¿QUÉ OFRECEMOS?"
}
export const content_text1 = [
	{
		title: 'Ejecución de pruebas',
		content:
			'Nos encargamos de la planeación y ejecución de diferentes tipos de pruebas, previo diagnóstico. Nuestros colaboradores son expertos certificados en ejecución de pruebas para garantizar la realización en forma integral de los procesos definidos y así certificar productos de alta calidad.',
		image: 'https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/block_1.jpg?alt=media&token=7f7e40c9-c373-4a0a-ab3d-d39020610587',
	},
	{
		title: 'Consultoría en temas de pruebas',
		content:
			'Usted cuenta con un experto en Aseguramiento de calidad de software quien a través de su conocimiento, experiencia y metodología, le ayudará a evaluar el estado actual de su proceso de calidad de software para detectar sus necesidades. Asignamos un consultor con conocimiento de la metodología implementada para que acompañe a su equipo de trabajo a ejecutarla. Proveemos capacitación y sensibilización en calidad de software a todos los integrantes del equipo de trabajo.',
		image: 'https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/block_2.jpg?alt=media&token=17b4e3e7-ef0c-4424-a4e0-207f915b402b',
	},
	{
		title: 'Automatización de pruebas',
		content:
			'Contamos con un equipo humano con conocimientos en herramientas de automatización que combinadas con su propia experiencia en ejecución de pruebas velan por que la automatización se convierta en una característica clave del desarrollo ágil del software.',
		image: 'https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/block_3.jpg?alt=media&token=d03ba4a3-d713-4f92-a8fb-6a859841177b',
	},
]
export const content_text2 = [
	{
		title: 'Consultoría técnica JDE ONEWORLD',
		content:
			'Nuestros consultores tienen amplio conocimiento en análisis y desarrollo de requerimientos sobre JDE y soporte técnico en la herramienta.  Asignamos ingenieros de sistemas expertos en desarrollo de software, para garantizar la realización en forma integral de los procesos definidos y así desarrollar productos de alta calidad. Participamos desde el levantamiento del requisito para realizar el diseño y análisis de la solución.',
			image: 'https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/block_4.jpg?alt=media&token=bd6ba721-4314-4a48-8ae6-96291bf973c2',
	},
	{
		title: 'Ejecución de pruebas',
		content:
			`Modelado y automatización de procesos.

			Ofrecemos disponibilidad de consultores certificados directamente con Bizagi.
			
			Participamos en la definición e implementación de mejoras en los procesos a partir de la experiencia.`,
			image: 'https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/block_5.jpg?alt=media&token=6f1370e9-0f17-4845-8306-fd7fa58ef0c8',
	},
]

