import React from 'react';
import Slider from '../..';
import smSliderCss from './smslider.css';

function SmSlider() {
	return (
			<Slider classNames={smSliderCss} autoplay={20000} >
				<div className={smSliderCss.container}>
					<div className={smSliderCss.title}>NOSOTROS</div>
					<div className={smSliderCss.text}>La importancia que se está dando al Aseguramiento de calidad de software
														ha dado origen al surgimiento de empresas como <b>QUALITY RESULTS S.A.S</b>, quien pone
														a su disposición el conocimiento y la experiencia en la certificación y aseguramiento de calidad de software.</div>
					<div className={smSliderCss.text}>Ofrecemos servicios profesionales para acompañarlo en los procesos de desarrollo de software que actualmente adelanta, permitiéndole dar respuesta efectiva a sus necesidades actuales de certificación y contar con nosotros como sus aliados en calidad de software.</div>
					<img className={smSliderCss.image} src='https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/sm_1.jpg?alt=media&token=c1b30dde-6d8e-4667-bf0b-7e0d657a6b91' />
				</div>
				<div className={smSliderCss.container}>
					<div className={smSliderCss.title}>¿CÓMO LO HACEMOS?</div>
					<div className={smSliderCss.text}>Basados en una <b>oferta de valor</b> que garantiza que realizamos nuestras actividades cumpliendo con altos niveles de servicio, utilizando <b>metodologías </b> basadas en las mejores prácticas del mercado a nivel mundial en (ISTQB)
              			y en <b> lecciones aprendidas</b> a lo largo de nuestra experiencia, teniendo muy claro el nivel de servicios, que es definido en forma personalizada y con proyección de escalabilidad, gracias a la implementación de una solución precisa que se ajusta a los <b>objetivos de nuestros clientes.</b></div>
					<img className={smSliderCss.image} src='https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/sm_2.jpg?alt=media&token=4e5e5160-3df9-45ad-a5b4-9e59ffbabf28' />
				</div>
				<div className={smSliderCss.container}>
					<div className={smSliderCss.title}>NOSOTROS</div>
					<div className={smSliderCss.text}>La importancia que se está dando al Aseguramiento de calidad de software
														ha dado origen al surgimiento de empresas como <b>QUALITY RESULTS S.A.S</b>, quien pone
														a su disposición el conocimiento y la experiencia en la certificación y aseguramiento de calidad de software.</div>
					<div className={smSliderCss.text}>Ofrecemos servicios profesionales para acompañarlo en los procesos de desarrollo de software que actualmente adelanta, permitiéndole dar respuesta efectiva a sus necesidades actuales de certificación y contar con nosotros como sus aliados en calidad de software.</div>
					<img className={smSliderCss.image} src='https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/sm_1.jpg?alt=media&token=c1b30dde-6d8e-4667-bf0b-7e0d657a6b91' />
				</div>
				<div className={smSliderCss.container}>
					<div className={smSliderCss.title}>¿CÓMO LO HACEMOS?</div>
					<div className={smSliderCss.text}>Basados en una <b>oferta de valor</b> que garantiza que realizamos nuestras actividades cumpliendo con altos niveles de servicio, utilizando <b>metodologías </b> basadas en las mejores prácticas del mercado a nivel mundial en (ISTQB)
              			y en <b> lecciones aprendidas</b> a lo largo de nuestra experiencia, teniendo muy claro el nivel de servicios, que es definido en forma personalizada y con proyección de escalabilidad, gracias a la implementación de una solución precisa que se ajusta a los <b>objetivos de nuestros clientes.</b></div>
					<img className={smSliderCss.image} src='https://firebasestorage.googleapis.com/v0/b/panendo-57b6b.appspot.com/o/sm_2.jpg?alt=media&token=4e5e5160-3df9-45ad-a5b4-9e59ffbabf28' />
				</div>
			</Slider>
		// </div>
	);
}

export default SmSlider;
