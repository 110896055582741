import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import axios from 'axios';

import 'react-toastify/dist/ReactToastify.css';
import styles from './withus.css';
import { render } from "react-dom";

var flag = false;

const onChange = () => {
  flag = true;
}



function WithUs(props) {

  const [value, setValue] = useState({
    selectedFile: null
  })
  const setFieldValue = (event) => {
    setValue({ ...value, selectedFile: event.target.files[0] })
  }
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      file: "",
    },
    validate(values) {
      const errors = {}

      if (!values.email) {
        errors.email = "¡El campo de correo electrónico es obligatorio!"
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "¡El email debe ser válido!"
      }
      else if (!values.name) {
        errors.name = "¡El campo de nombre es obligatorio!"
      }
      else if (!values.phone) {
        errors.phone = "¡El campo del número de teléfono es obligatorio!"
      }
      else if (!values.message) {
        errors.message = "¡El campo de mensaje es obligatorio!"
      }

      return errors
    },
    onSubmit(values) {
      let formData = new FormData();
      formData.append('email',values.email);
      formData.append('text',values.message);
      formData.append('name',values.name);
      formData.append('phone',values.phone);
      formData.append('filename',value.selectedFile.name);
      formData.append('file', value.selectedFile);
      if (!flag)
        toast.error('VERIFY YOUR DATA!')
      else {
        axios.post('https://peaceful-lowlands-15840.herokuapp.com/sendfileattachedmail',
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            headers: { 'Access-Control-Allow-Origin': '*' }
          }).then(response => {
            if(response.data == "success"){
              toast.info("Mail was sent correctly.");
            }
            else{
                toast.error('Mail was not sent correctly.');
            }
          })
      }
    },
  })

  return (
    <div className={styles.withus}>
      <h2 className={styles.title}>TRABAJA CON NOSOTROS</h2>
      <p className={styles.description}>Registra el formulario y espera una pronta respuesta!</p>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <input
          type="text"
          name="name"
          placeholder="Nombre"
          className={formik.errors.name ? "error" : null}
          onChange={formik.handleChange}
          value={formik.values.name}
        />
        {formik.errors.name ? (
          <span className='errorText'>{formik.errors.name}</span>
        ) : null}
        <input
          type="text"
          name="phone"
          placeholder="Número telefónico contacto"
          className={formik.errors.phone ? "error" : null}
          onChange={formik.handleChange}
          value={formik.values.phone}
        />
        {formik.errors.phone ? (
          <span className='errorText'>{formik.errors.phone}</span>
        ) : null}
        <input
          type="text"
          name="email"
          placeholder="Email"
          className={formik.errors.email ? "error" : null}
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.errors.email ? (
          <span className="errorText">{formik.errors.email}</span>
        ) : null}
        <textarea
          name="message"
          placeholder="Mensaje"
          className={formik.errors.message ? "error" : null}
          onChange={formik.handleChange}
          value={formik.values.message}
        />
        <input
          id="file"
          name="file"
          type="file"
          onChange={(e) => setFieldValue(e)}
        // className={formik.errors.file ? "error" : null}
        // onChange={formik.handleChange}
        // value={formik.values.file}
        />
        <button type="submit">Enviar mensaje</button>
        <br />
        <ReCAPTCHA
          sitekey="6LdmOcMZAAAAANCEgw3BYiw2h8-vvlIVTzqYgcKj"
          onChange={onChange}
        />
      </form>
    </div>

  );
  // }}
}

export default WithUs;