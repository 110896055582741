import React from "react";
import styles from './footer.css'; 

function Footer() {

  return (    
    <div className={styles.footer}>
        <p  className={styles.text} >3216533 - 3207203584</p>
        <p  className={styles.text} >info@q-results.com.co</p>
        <p  className={styles.text} >Calle 3 Sur # 41 - 65 oficina 1104</p>
        <p  className={styles.text} >&copy; Quality Results - Derechos reservados</p>
    </div>

  );
}

export default Footer;