import React from "react";
import { ToastContainer, toast } from 'react-toastify';  
import 'react-toastify/dist/ReactToastify.css';   
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik" 
import axios from 'axios';

import styles from './contactform.css'; 
import "../../css/styles.css";
import 'react-toastify/dist/ReactToastify.css'; 

var flag = false;

const onChange = () => {
    flag = true;
} 

function ContactForm() {
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: "",
            recapcha: "",
        },
        validate(values) {
            const errors = {}
    
            if (!values.email) {
                errors.email = "¡El campo de correo electrónico es obligatorio!"
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
                errors.email = "¡El email debe ser válido!"
            }
            else if(!values.name){
                errors.name = "¡El campo de nombre es obligatorio!"
            }
            else if(!values.message){
                errors.message = "¡El campo de mensaje es obligatorio!"
            }
    
            return errors
        },
        onSubmit(values) {
            const userinfo = values;
            if(!flag)
                toast.error('VERIFY RECAPTCHA!')
            else{
                let formData = new FormData();
                formData.append('email',userinfo.email);
                formData.append('text',userinfo.message);
                formData.append('name',userinfo.name);
                axios.post('https://peaceful-lowlands-15840.herokuapp.com/sendmail',
                formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'},
                    headers: {'Access-Control-Allow-Origin': '*'}
                }).then(response => {
                    if(response.data == "success"){
                        toast.info("Mail was sent correctly.");
                    }
                    else{
                        toast.error('Mail was not sent correctly.');
                    }
                })
            }
        },
    })
    
  return (    
      
      <div className={styles.contactform}>
          <h1 className={styles.title}>Formulario de contacto</h1>
          <form onSubmit={formik.handleSubmit} className={styles.form}>
			<input
				type="text"
				name="name"
				placeholder="Nombre"
				className={formik.errors.name ? "error" : null}
				onChange={formik.handleChange}
				value={formik.values.name}
			/>
			{formik.errors.name ? (
				<span className='errorText'>{formik.errors.name}</span>
			) : null}
			<input
				type="text"
				name="email"
				placeholder="Email"
				className={formik.errors.email ? "error" : null}
				onChange={formik.handleChange}
				value={formik.values.email}
			/>
			{formik.errors.email ? (
				<span className="errorText">{formik.errors.email}</span>
			) : null}
			<textarea
				name="message"
				placeholder="Mensaje"
				className={formik.errors.message ? "error" : null}
				onChange={formik.handleChange}
				value={formik.values.message}
			/>
			{formik.errors.message ? (
				<span className="errorText">{formik.errors.message}</span>
			) : null}
			<button type="submit">Enviar mensaje</button>
		</form>
            <ReCAPTCHA
                sitekey="6LdmOcMZAAAAANCEgw3BYiw2h8-vvlIVTzqYgcKj" 
				onChange={onChange}
            />,
        <ToastContainer />
      </div>
   
  );
}

export default ContactForm;