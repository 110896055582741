import React from "react";
import styles from './block.css'; 

import { block_header_text, content_text1, content_text2, } from '../../utilites/constants';
import  Blog  from '../blog/blog';

function Block() {
  return (
       <div className={styles.block}>
           <h2 className={styles.title}>
                {block_header_text.text}
           </h2>
           <div className={styles.body_block}>
           {
                content_text1.map((item) => (
                    <Blog title={item.title} content={item.content} image={item.image}/>
                ))
           }
           </div>
           <div className={styles.body_block2}>
           {
                content_text2.map((item) => (
                    <Blog title={item.title} content={item.content} image={item.image}/>
                ))
           }
           </div>
           
       </div>
  );
}

export default Block;