import React from 'react';
import Slider from '../..';
import bgSliderCss from './bgslider.css';
import content from '../../utilites/constants';

function BgSlider() {
	return (
		<div>
			<Slider classNames={bgSliderCss} autoplay={8000} duration={3000}>
				{content.map((item, index) => (
					<div
						key={index}
						className={bgSliderCss.sliderContent}
						style={{ background: `url('${item.image}') no-repeat center center` }}
					>
						<div className={bgSliderCss.inner}>
							<h1>{item.title}</h1>
							<p>{item.description}</p>
							{/* <button>{item.button}</button> */}
						</div>
					</div>
				))}
			</Slider>
		</div>
	);
}

export default BgSlider;
