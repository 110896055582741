import React from "react";
import styles from './header.module.css'; 

import { text, navItem } from '../../utilites/constants';

function Header() {

  return (    
    <header>
         <div className={styles.row} id="headerid">
             <div className={styles.header_link_div}>
                 <a className={styles.header_link} href='/'>
                     {text.header_text}
                 </a>    
             </div>
             <div className={styles.header_nav_div}>
                 {
                     navItem.map(item => (
                         <div className={styles.header_li}>
                             <a href={item.url} className={styles.ul_li_a}>{item.text}</a>
                         </div>
                     ))
                 }
             </div>
        </div>
    </header>
    
   
  );
}

export default Header;