import React from "react";
import styles from './location.css'; 

function Location(props) {

  const style = {
    width: '300px',
    height: '300px'
  }

  return (    
      <div className={styles.location}>
          <h2 className={styles.title}>Visualiza nuestra localización</h2>
          <iframe className={styles.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.477478200871!2d-75.57445308573413!3d6.200565228554625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e46828716de13e9%3A0x776bd054de9e0807!2sCl.%203%20Sur%20%23%2341-65%2C%20Medell%C3%ADn%2C%20Antioquia!5e0!3m2!1ses!2sco!4v1587049624354!5m2!1ses!2sco" ></iframe>
      </div>
   
  );
}

// export default GoogleApiWrapper({
//   apiKey: ('!1m18!1m12!1m3!1d3966.477478200871!2d-75.57445308573413!3d6.200565228554625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e46828716de13e9%3A0x776bd054de9e0807!2sCl.%203%20Sur%20%23%2341-65%2C%20Medell%C3%ADn%2C%20Antioquia!5e0!3m2!1ses!2sco!4v1587049624354!5m2!1ses!2sco')
// })(Location);
export default Location;