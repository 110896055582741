import React from "react";
import styles from './contact.css'; 

import { text, navItem } from '../../utilites/constants';

import ContactForm from '../contactform/contactform';
import Location from '../location/location';

function Contact() {

  return (    
      <div className={styles.contact}>
          <h2 className={styles.title}> CONTÁCTANOS </h2>
          <p className={styles.description}>Registra el formulario y espera una pronta <br/> respuesta!</p>
          <div className={styles.contain}>
            <ContactForm />
            <Location />
          </div>
      </div>
   
  );
}

export default Contact;