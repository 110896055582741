import React from "react";
import styles from './blog.css'; 

function Blog(props) {
    return (    
        <div className={styles.blog}>
            <img className={styles.image} src={props.image}></img>
            <div className={styles.title}>
                {props.title}
            </div>
            <div className={styles.content}>
                {props.content}
            </div>
        </div>
    );
}

export default Blog;