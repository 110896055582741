import React from 'react';
import { render } from 'react-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

import Header from '../components/header/header';
import BgSlider from '../components/bgslider/bgslider';
import SmSlider from '../components/smslider/smslider';
import Block from '../components/block/block';
import Contact from '../components/contact/contact';
import WithUs from '../components/withus/withus';
import Footer from '../components/footer/footer';

import "react-perfect-scrollbar/dist/css/styles.css";
import styles from '../css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';    

import { Link, animateScroll as scroll } from "react-scroll";

const scrollToTop = () => {
	scroll.scrollToTop();
};

function App() {
	window.addEventListener('scroll', (event) => {
		var x = document.getElementsByTagName('header');
		if(window.scrollY > 100){
			x[0].style.backgroundColor = "rgb(19 18 18)";
			x[0].style.opacity = 1;
		}
		else if(window.scrollY < 100){
			x[0].style.backgroundColor = 'black'
			x[0].style.opacity = 0.8;
		}
	  });
	return (
		<PerfectScrollbar
				className="header"
				option={{ suppressScrollY: true }}
			>
			<div>
				<Header /> 
				<a href="#" className={styles.upbutton}  onClick={()=>scrollToTop()}>
					<FontAwesomeIcon icon='arrow-up' className={styles.icon}/>
				</a>
				<section id="intro" >
					<BgSlider />
				</section>
				<section id="quienessomos" >
					<SmSlider />
				</section>
				<section id="about">
					<Block /> 
				</section>
				<section id="contact">
					<Contact />
				</section>
				<section id="trabaja">
					<WithUs />
				</section>
				<Footer />
			</div>

		</PerfectScrollbar >
		
	);
}

library.add(fas)

render(<App />, document.getElementById('app'));
